// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_cN23dfdRz9FL024fZA", // Repaint Image 
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/5kA14XgrY0l0ebecNn", // Repaint Image

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Repaint Image AI 🎨",
    HOME_PAGE_TITLE: "Home - Repaint Image AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Repaint Image AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Repaint Image AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to add masks to images, choose your image, enter your mask name and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "repaint_img_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/repaint-img-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;